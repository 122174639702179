var Siemens = {};

Siemens.rbcMenu = {
  showMenu: function() {
    $('.js-header').addClass('active');
  },
  hideMenuTimeout: false,
  hideMenu: function() {
    clearTimeout(this.hideMenuTimeout);
    this.hideMenuTimeout = setTimeout(function(){
      $('.js-header').removeClass('active');
    }, 1000);
  },
  init: function(){
    var t = this;
    $('.js-header-open').on('click', function(){
      t.showMenu();
      return false;
    });
    $('.js-header').on('mouseover', function(){
      clearTimeout(t.hideMenuTimeout);
    });
    $('.js-header').on('mouseleave', function(){
      t.hideMenu();
    });
  },
};
Siemens.share = {

  sharingPopupShow: function sharingPopupShow(clickedElem) {
    var network = clickedElem.attr('class'),
        networkUrl = clickedElem.attr('href'),
        pageUrl = window.location.href,
        text = $("meta[name='og:title']").attr('content'),
        url = '';
    if (network == 'vk') {
      url = networkUrl + pageUrl;
    }
    if (network == 'tw') {
      pageUrl = text + ': ' + pageUrl;
      url = networkUrl + pageUrl;
    } else if (network == 'fb') {
      url = networkUrl + pageUrl;
    }else if (network == 'go') {
      url = networkUrl + pageUrl;
    }

    window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
  },

  init: function init() {
    $('.js-share a').on('click', function (e) {
      e.preventDefault();
      var clickedElem = $(this);
      Siemens.share.sharingPopupShow(clickedElem);
    });
  }
};

Siemens.counters = {
  init: function(){
    $('.fb').on('click',function(){
      ga("send", "event", "share", "clicked share button", "facebook");
    });
    $('.vk').on('click',function(){
      ga("send", "event", "share", "clicked share button", "vkontakte");
    });
    $('.tw').on('click',function(){
      ga("send", "event", "share", "clicked share button", "twitter");
    });
    $('.go').on('click',function(){
      ga("send", "event", "share", "clicked share button", "gplus");
    });

    function seconds() {
      ga("send", "event", "page", "spent_on_page_15_sec");
    }
    setTimeout(seconds, 15000);


    $('.ga-index').on('click', function(){
      var btnName = $(this).html();
      ga("send", "event", "main page", "clicked link in block", btnName);
    });

    $('.ga-longread').on('click', function(){
      var btnName = $(this).html();
      ga("send", "event", "longread page", "clicked link in block", btnName);
    });




    // $('.ga-info-longread').on('click', function(){
    //   var btnName = $(this).html();
    //   ga("send", "event", "infographics page", "clicked link in block", btnName);
    // });

    $('.siemens-logo, .siemens-logo-white').on('click', function(){

      ga("send", "event", "brand button", "clicked header", "https://www.siemens.com/ru/ru/home.html");

      $('body').append('<img src="https://www.siemens.com/ru/ru/home.html?csref=dsp_RBC_Siemens_INGENUITY_for_life_text_announcements" style="opacity: 0;"></img>');
      $('body').append('<img src="http://ad.adriver.ru/cgi-bin/click.cgi?sid=1&bt=2&ad=593463&pid=2385522&bid=4559320&bn=4559320&rnd=2401192" style="opacity: 0;"></img>');
    });
  },
};

Siemens.slider = {
  init: function(){
    $('.slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        dots:false,
        infinite:false,
        speed: 1000,
    });

  if($('.slider').height){
    var $slickElement = $('.slider');
    var k;
    $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      k = (currentSlide ? currentSlide : 0);
      var i = k+1;
      var str = 'screen_' + i;
      ga("send", "event", "infographics page", "change screen", str);
      if($('.desc-left-second').height){
        if(i == 2){
          $('.desc-left-second').css('display','block');
        }else{
          $('.desc-left-second').css('display','none');
        }
      }
      if($('.desc-left-1').height){
        if(i == 2){
          $('.desc-left-1').css('display','block');
        }else{
          $('.desc-left-1').css('display','none');
        }
      }
      if ($('.desc-left-1-2').height) {
        if (i === 2) {
          $('.desc-left-1-2').css('display', 'block');
          $('.desc-left-1-1').css('display', 'none');
        } else {
          $('.desc-left-1-1').css('display', 'block');
          $('.desc-left-1-2').css('display', 'none');
        }
      }
    });
  }

  var action = true;
  $(document).mousewheel(function(e) {
      e.preventDefault();
      if(action === true){
        action = false;
        if (e.deltaY < 0) {
            $('.slider').slick('slickNext');
        }
        else {
            $('.slider').slick('slickPrev');
        }
        setTimeout(function(){
          action = true;
        }, 1000);
      }
  });

  $(document).keydown(function( event ) {
    if(action === true){
    if ( event.keyCode == 33 || event.keyCode == 37) {
        $('.slider').slick('slickPrev');
    }else if( event.keyCode == 34 || event.keyCode == 39){
      $('.slider').slick('slickNext');
    }
    action = false;
    setTimeout(function(){
      action = true;
    }, 1000);
  }

  });
  $('.js-prev-slide').on('click', function(){
    $('.slider').slick('slickPrev');
  });
  $('.js-next-slide').on('click', function(){
    $('.slider').slick('slickNext');
  });
  $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    // ga("send", "event", "infographics page", "change screen", nextSlide);

    if(nextSlide == 1){
      $('.sky-1').addClass('sky-1-forward-mid');
      $('.bus-1').addClass('bus-1-forward-mid');
      $('.car-1').addClass('car-1-forward-mid');
      $('.car-2').addClass('car-2-forward-mid');
      $('.mid-train').addClass('mid-train-forward-mid');
      $('.big-train').addClass('big-train-forward-mid');
      $('.train-2').addClass('train-2-forward-mid');
      $('.metro').addClass('metro-forward-mid');
      $('.cars-3').addClass('cars-3-forward-mid');
      $('.cars-4').addClass('cars-4-forward-mid');
      $('.cars-5').addClass('cars-5-forward-mid');
      $('.cars-minibus-2').addClass('cars-minibus-2-forward-mid');//myfix
      $('.cars-truck-2-l').addClass('cars-truck-2-l-forward-mid');//myfix
      $('.cars-bigbus').addClass('cars-bigbus-forward-mid');//myfix
      $('.cars-bigtruck').addClass('cars-bigtruck-forward-mid');//myfix
      $('.cars-minibus-1').addClass('cars-minibus-1-forward-mid');//myfix
      $('.cars-truck-1-l').addClass('cars-truck-1-l-forward-mid');//myfix
      $('.cars-2w-l').addClass('cars-2w-l-forward-mid');//myfix
      $('.cars-2w-r').addClass('cars-2w-r-forward-mid');//myfix
      $('.cars-3w').addClass('cars-3w-forward-mid');
      $('.cars-4w').addClass('cars-4w-forward-mid');
      $('.cars-5w').addClass('cars-5w-forward-mid');
      $('.cars-6w').addClass('cars-5w-forward-mid');     
      $('.bus-2').addClass('bus-2-forward-mid');
      $('.bus-3').addClass('bus-3-forward-mid');
      $('.bus-4').addClass('bus-4-forward-mid');
      $('.buildings-1, .roads-1, .peoples-1, .js-trees-1').addClass('street-forward-mid');
      $('.buildings-2, .roads-2, .peoples-2, .js-trees-2').addClass('street-2-forward-mid');
      $('.buildings-3, .roads-3, .peoples-3, .js-trees-3').addClass('street-2-forward-mid');
      $('.buildings-6, .roads-6, .peoples-6, .js-trees-6').addClass('street-2-forward-mid');
    }else{
      $('.sky-1').removeClass('sky-1-forward-mid');
      $('.bus-1').removeClass('bus-1-forward-mid');
      $('.car-1').removeClass('car-1-forward-mid');
      $('.car-2').removeClass('car-2-forward-mid');
      $('.train-2').removeClass('train-2-forward-mid');
      $('.metro').removeClass('metro-forward-mid');
      $('.cars-3').removeClass('cars-3-forward-mid');
      $('.cars-4').removeClass('cars-4-forward-mid');
      $('.cars-5').removeClass('cars-5-forward-mid');
      $('.cars-minibus-2').removeClass('cars-minibus-2-forward-mid');//myfix
      $('.cars-truck-2-l').removeClass('cars-truck-2-l-forward-mid');//myfix
      $('.cars-bigbus').removeClass('cars-bigbus-forward-mid');//myfix
      $('.cars-bigtruck').removeClass('cars-bigtruck-forward-mid');//myfix
      $('.cars-minibus-1').removeClass('cars-minibus-1-forward-mid');//myfix
      $('.cars-truck-1-l').removeClass('cars-truck-1-l-forward-mid');//myfix
      $('.cars-2w-l').removeClass('cars-2w-l-forward-mid');//myfix
      $('.cars-2w-r').removeClass('cars-2w-r-forward-mid');//myfix
      $('.cars-3w').removeClass('cars-3w-forward-mid');
      $('.cars-4w').removeClass('cars-4w-forward-mid');
      $('.cars-5w').removeClass('cars-5w-forward-mid');
      $('.cars-6w').removeClass('cars-5w-forward-mid');
      $('.bus-2').removeClass('bus-2-forward-mid');
      $('.bus-3').removeClass('bus-3-forward-mid');
      $('.bus-4').removeClass('bus-4-forward-mid');
      $('.mid-train').removeClass('mid-train-forward-mid');
      $('.big-train').removeClass('big-train-forward-mid');
      $('.buildings-1, .roads-1, .peoples-1, .js-trees-1').removeClass('street-forward-mid');
      $('.buildings-2, .roads-2, .peoples-2, .js-trees-2').removeClass('street-2-forward-mid');
      $('.buildings-3, .roads-3, .peoples-3, .js-trees-3').removeClass('street-2-forward-mid');
      $('.buildings-6, .roads-6, .peoples-6, .js-trees-6').removeClass('street-2-forward-mid');
    }
    if(nextSlide == 2 || nextSlide == 3){
      $('.sky-1').addClass('sky-1-forward-max');
      $('.bus-1').addClass('bus-1-forward-max');
      $('.cars-1').addClass('cars-1-forward-max');
      $('.cars-2').addClass('cars-2-forward-max');
      $('.mid-train').addClass('mid-train-forward-max');
      $('.big-train').addClass('big-train-forward-max');
      $('.mid-train').addClass('mid-train-forward-mid');
      $('.big-train').addClass('big-train-forward-mid');
      $('.train-2').addClass('train-2-forward-mid');
      $('.metro').addClass('metro-forward-mid');
      $('.cars-3').addClass('cars-3-forward-mid');
      $('.cars-4').addClass('cars-4-forward-mid');
      $('.cars-5').addClass('cars-5-forward-mid');
      $('.cars-minibus-2').addClass('cars-minibus-2-forward-mid');//myfix
      $('.cars-truck-2-l').addClass('cars-truck-2-l-forward-mid');//myfix
      $('.cars-bigbus').addClass('cars-bigbus-forward-mid');//myfix
      $('.cars-bigtruck').addClass('cars-bigtruck-forward-mid');//myfix
      $('.cars-minibus-1').addClass('cars-minibus-1-forward-mid');//myfix
      $('.cars-truck-1-l').addClass('cars-truck-1-l-forward-mid');//myfix
      $('.cars-2w-l').addClass('cars-2w-l-forward-mid');//myfix
      $('.cars-2w-r').addClass('cars-2w-r-forward-mid');//myfix
      $('.cars-3w').addClass('cars-3w-forward-mid');
      $('.cars-4w').addClass('cars-4w-forward-mid');
      $('.cars-5w').addClass('cars-5w-forward-mid');
      $('.cars-6w').addClass('cars-5w-forward-mid');
      $('.bus-2').addClass('bus-2-forward-mid');
      $('.bus-3').addClass('bus-3-forward-mid');
      $('.bus-4').addClass('bus-4-forward-mid');
      $('.buildings-1, .roads-1, .peoples-1, .trees').addClass('street-forward-max');
      $('.buildings-2, .roads-2, .peoples-2, .js-trees-2').addClass('street-2-forward-mid');
      $('.buildings-3, .roads-3, .peoples-3, .js-trees-3').addClass('street-2-forward-mid');
      $('.buildings-6, .roads-6, .peoples-6, .js-trees-6').addClass('street-2-forward-mid');
    }else{
      $('.sky-1').removeClass('sky-1-forward-max');
      $('.bus-1').removeClass('bus-1-forward-max');
      $('.cars-1').removeClass('cars-1-forward-max');
      $('.cars-2').removeClass('cars-2-forward-max');
      $('.mid-train').removeClass('mid-train-forward-max');
      $('.big-train').removeClass('big-train-forward-max');
      $('.buildings-1, .roads-1, .peoples-1, .trees').removeClass('street-forward-max');
      $('.buildings-6, .roads-6, .peoples-6, .js-trees-6').removeClass('street-2-forward-max');
    }
    if(nextSlide >=1){
      $('.prev-btn').fadeIn();
    }else{
      $('.prev-btn').fadeOut();
    }
    if(nextSlide == (slick.slideCount - 1)){
      $('.next-btn').fadeOut();
    }else{
      $('.next-btn').fadeIn();
    }

  });

  },
};

Siemens.sliderMob = {
  init: function(){
    $('.slider-mobile').on('init', function(event, slick){
      $('[data-text="1"]').show();
    });
    $('.slider-mobile').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        infinite:false,
        speed: 1000,
        dots: true
    });

    $('.js-prev-slide').on('click tap', function(){
      $('.slider-mobile').slick('slickPrev');

    });
    $('.js-next-slide').on('click tap', function(){
      $('.slider-mobile').slick('slickNext');

    });
    var railPos = 0;
    $('.slider-mobile').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      // ga("send", "event", "infographics page", "change screen", nextSlide);

      if(nextSlide>currentSlide){
        railPos = railPos - 100;
        $('.mobile-railroad, .mobile-railroad-1').css('webkitTransform', 'translateX(' + railPos + 'px)');
      }else if(nextSlide<currentSlide){
        railPos = railPos + 100;
        $('.mobile-railroad, .mobile-railroad-1').css('webkitTransform', 'translateX(' + railPos + 'px)');
      }else if(currentSlide === 0){
        railPos = 0;
      }
      if(nextSlide >2 && nextSlide < 4){
        $('[data-text="2"]').show();
        $('[data-text="1"]').hide();
        $('[data-text="3"]').hide();
      }else if(nextSlide >5 && nextSlide <9){

        $('[data-text="3"]').show();
        $('[data-text="1"]').hide();
        $('[data-text="2"]').hide();
      }else if(nextSlide <3){

        $('[data-text="1"]').show();
        $('[data-text="2"]').hide();
        $('[data-text="3"]').hide();
      }

      if(nextSlide >=1){
        $('.prev-btn').fadeIn();
      }else{
        $('.prev-btn').fadeOut();
      }
      if(nextSlide == (slick.slideCount - 1)){
        $('.next-btn').fadeOut();
      }else{
        $('.next-btn').fadeIn();
      }
    });
  },
};

Siemens.menu = {
  init: function(){
    $('.js-open').on('click', function(){
      $('.js-menu').addClass('active');
      $('.js-close').show();
      $('body').addClass('fixed');
      $('.top-block').css('z-index','0');
      $('main').css('display','none');
      $('footer').css('display','none');
    });
    $('.js-close').on('click', function(){
      $('.js-menu').removeClass('active');
      $(this).hide();
      $('body').removeClass('fixed');
      $('.top-block').css('z-index','1050');
      $('main').css('display','block');
      $('footer').css('display','block');
    });
  },
};

Siemens.infoslider = {
  init: function() {
    $(window).resize(function() {
      var index = buttons.index($('.colored-button--checked'));
      first.stop().animate({
        marginLeft: - first.width() * index
      }, 200);
    });
    var infoslider = $('.js-infoslider');
    var slides = infoslider.find('.info-list');
    var first = slides.eq(0);
    var buttons = $('.js-infoslider-button');
    buttons.on('click', function(e) {
      if (!$(this).hasClass('colored-button--checked')) {
        buttons.removeClass('colored-button--checked');
        $(this).addClass('colored-button--checked')
        first.stop().animate({
          marginLeft: - first.width() * buttons.index($(this))
        }, 800);
      }
    });
  },
}

Siemens.link_scroll = {
  init: function(){
    //Плавный скроллинг
    $(".navigation").on("click", "a", function (event) {
      // $(this).removeClass('active').addClass('active');
      var id = $(this).attr('href');
      var top = $(id).offset().top;
      $('body,html').animate({ scrollTop: top }, 1500);
      // $(".navigation a").removeClass('active');
      // $(this).addClass('active');
      //$('.navigation').mCustomScrollbar("scrollTo",$('.navigation').find('.mCSB_container').find($(this)));
    });
  }
}

Siemens.scrollbar = {
  init: function(){
     $(window).on("load",function(){
        $(".navigation").mCustomScrollbar({
          mouseWheelPixels: 400
        });
    });
  }
}

Siemens.fly_navigation = {
  init: function(){
    $(window).scroll(function() {
      if($('#goals').length && $('.article-links').length){
        var top_min = $('#goals').offset().top;
        var top_max = $('.article-links').offset().top;
        var h = $('.navigation').outerHeight();
        var top = $(document).scrollTop();
        if((top > top_min) && (top < top_max - h)){$('.holder').addClass('fixed').removeAttr("style");}
        else if(top > top_max - h){$('.holder').removeClass('fixed').css({'position':'absolute','bottom':'0'});}
        else {$('.holder').removeClass('fixed');}
      }
    });
  }
}

Siemens.nohref = {
  init: function(){
    var w = $(window).width();
    if(w < 646){
      $('.no-href').attr('href','#');
      $('.no-href').css('display','none');
    }
    var h = $(window).height();
    if(h < 640){
      $('.desc').css('display', 'none');
    }
  }
}

Siemens.control_scroll = {
  init: function(){
    var canScroll = true;
    window.controlScrollTimeout = 0;
    $(document).mousemove(function(event) {
      if ($(event.target).closest('.navigation').length) {
        canScroll = false;
      } else {
        canScroll = true;
      }
    });
    $(window).scroll(function(event) {
      var links = $('.doc-section-link');
      var centerScreenTop = $(document).scrollTop() + parseInt($(window).outerHeight() / 3);
      var speedFlg = false; // Flag for performance
      $('.navigation').mCustomScrollbar("stop");
      $('section').each(function(index) {
        if (!speedFlg) {
          var secStart = $(this).offset().top;
          var secEnd = secStart + $(this).outerHeight();
          if (centerScreenTop >= secStart && centerScreenTop < secEnd) {
            speedFlg = true;
            window.currentLink = links.filter('[href="#' + this.id + '"]');
            links.not(window.currentLink[0]).removeClass('active');
            if (canScroll) {
              $('.navigation').mCustomScrollbar('scrollTo', $('.navigation').find(window.currentLink));
            }
            if (window.controlScrollTimeout) {
              clearTimeout(window.controlScrollTimeout);
              window.controlScrollTimeout = 0;
            }
            window.controlScrollTimeout = setTimeout(function() {
              window.currentLink.addClass('active');
            }, 300);
          }
        }
      });
    });
  }
};

Siemens.title_show = {
  init: function(){
    var w = $(window).width();
    if(w < 646){
      $('.title-js').click(function(){
        if($(this).next().next().hasClass("hidden-text")){
          $(this).next().next().removeClass('hidden-text');
          // $(this).next().attr('src', '/images/arrow-spin.png')
          $(this).next().slideDown("slow");
          // $(this).next().show("slow");
          // $(this).next().attr('src', '/img/arrow-spin.png')
        }else{
          // $(this).next().removeClass('block-active');
          $(this).next().next().addClass('hidden-text');
          // $(this).next().hide("slow");
          $(this).next().next().slideUp("slow");
          // $(this).next().attr('src', '/images/arrow.png')
          // $(this).next().attr('src', '/img/arrow.png')
        }
      });

      $('.title-vivod-js').click(function(){
        if($(this).next().next().hasClass("hidden-text")){
          $(this).next().next().removeClass('hidden-text');
          // $(this).next().attr('src', '/images/arrow-spin.png')
          $(this).next().next().slideDown("slow");
          // $(this).next().show("slow");
          // $(this).next().attr('src', '/img/arrow-spin.png')
        }else{
          $(this).next().next().addClass('hidden-text');
          $(this).next().next().slideUp("slow");
        }
      });

      // $('.event-js').click(function(){
      //   if($(this).next().next().hasClass("hidden-text")){
      //     $(this).next().next().removeClass('hidden-text');
      //     // $(this).next().addClass('block-active');
      //     $(this).next().next().show("slow");
      //     $(this).attr('src', '/images/arrow-spin.png')
      //     // $(this).attr('src', '/img/arrow-spin.png')
      //   }else{
      //     // $(this).next().removeClass('block-active');
      //     $(this).next().next().addClass('hidden-text');
      //     $(this).next().next().hide("slow");
      //     $(this).attr('src', '/images/arrow.png')
      //     // $(this).attr('src', '/img/arrow.png')
      //   }
      // });
    }
  }
};



$(document).ready(function () {
  Siemens.title_show.init();
  Siemens.nohref.init();
  Siemens.share.init();
  Siemens.rbcMenu.init();
  Siemens.menu.init();
  Siemens.counters.init();
  Siemens.infoslider.init();
  Siemens.link_scroll.init();
  Siemens.scrollbar.init();
  Siemens.fly_navigation.init();
  Siemens.control_scroll.init();
  var sliderVis = $('.slider').is(':visible');
  var slideMobVis = $('.slider-mobile').is(':visible');
  if(sliderVis){
    Siemens.slider.init();
  }
  if(slideMobVis){
    Siemens.sliderMob.init();
  }
  jQuery.scrollDepth({
    userTiming: false,
  });

  var loc = window.location.hash;
  if(loc == "#infrastructure"){
    $('.colored-button.js-infoslider-button.colored-button--infra').trigger('click');
  }
  if(loc == "#study"){
    $('.colored-button.js-infoslider-button.colored-button--research').trigger('click');
  }
  if(loc == "#energetics"){
    $('.colored-button.js-infoslider-button.colored-button--energy').trigger('click');
  }
  if(loc == "#capital"){
    $('.colored-button.js-infoslider-button.colored-button--capital').trigger('click');
  }
  var href = window.location.pathname;
  var w = $(window).width();
  if((href == "/infographic4.html") && (w > 646)){
    $('.slide-btn.next-btn.js-next-slide.icon-slide-btn.btn-orange').css('display', 'none');
  }
  if((href == "/infographic5.html") && (w > 646)){
    $('.slide-btn.next-btn.js-next-slide.icon-slide-btn.btn-green').css('display', 'none');
  }
});
